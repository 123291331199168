import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import mockUp3Images from "../../assets/mockUp3Images.png";
import DownloadBtn from "../../components/DownloadBtn";
import AboutUs from "./AboutUs";

// const listOfImages = [
//   { id: 0, imgSrc: ale01 },
//   { id: 1, imgSrc: luca01 },
//   { id: 2, imgSrc: ale02 },
//   { id: 3, imgSrc: ste01 },
//   { id: 4, imgSrc: ale03 },
//   { id: 5, imgSrc: user001 },
//   { id: 6, imgSrc: user004 },
// ];

function TopBanner({ handleOnClick, thumbnailAnimation, arrayOfImages }) {
  const { t } = useTranslation();

  return (
    <div className="top-banner-container">
      <div className="top-Main-container">
        <div className="above-carousel-container">
          <div id="transition-left">
            <h1>{t("Sport Fishing App")}</h1>
            <AboutUs />
            <h2>{t("Download the app")}</h2>
            <DownloadBtn flexTo={false} />
          </div>
          <div id="transition-right">
            <img src={mockUp3Images} alt="mockUp" />
          </div>
        </div>

        <div className="above-carousel-button">
          <div className="arrows">
            <button
              id="prev"
              onClick={() => handleOnClick("prev")}
              type="button"
            >
              &#10094;
            </button>
            <button
              id="next"
              onClick={() => handleOnClick("next")}
              type="button"
            >
              &#10095;
            </button>
          </div>

          <div className="thumbnail">
            {arrayOfImages.map((imgsrc, idx) => (
              <div
                key={idx}
                className="item"
                id={
                  idx === 0 ? thumbnailAnimation : "transition-thumbnailsNext"
                }
              >
                <img src={imgsrc} alt="thumbnail item" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TopBanner);
