import React, { useCallback, useEffect, useState } from "react";

import TopBanner from "./TopBanner";
import SlideShow from "./SlideShow";
import ale01 from "../../assets/ale01.jpeg";
import ale02 from "../../assets/ale02.jpeg";
import ale03 from "../../assets/ale03.jpeg";
import ste01 from "../../assets/ste01.jpeg";
import user001 from "../../assets/001.jpeg";
import user004 from "../../assets/004.jpeg";
import luca01 from "../../assets/luca01.jpeg";

const backgroundImages = [ale01, luca01, ale02, ste01, ale03, user001, user004];

function HomeScreen() {
  const [transition, setTransition] = useState("no-spacing");
  const [thumbnailAnimation, setThumbnailAnimation] =
    useState("hide-thumbnail");
  const [arrayOfImages, setArrayOfImages] = useState(backgroundImages);

  useEffect(() => {
    const interval = setInterval(() => {
      setArrayOfImages(moveItem(arrayOfImages, 0, arrayOfImages.length - 1));
      transit();
      transitThumbnails("next");
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [arrayOfImages]);

  const transit = () => {
    setTransition("transition-fade");
    setTimeout(() => {
      setTransition("no-spacing");
    }, 500);
  };

  const transitThumbnails = (dir) => {
    if (dir === "prev") {
      setThumbnailAnimation("transition-thumbnailsPrev");
    } else {
      setThumbnailAnimation("transition-thumbnailsNext");
    }
    // setThumbnailAnimation("transition-thumbnailsOut");
    setTimeout(() => {
      setThumbnailAnimation("hide-thumbnail");
    }, 500);
  };

  const moveItem = (array, from, to) => {
    const item = array[from];
    array.splice(from, 1);
    array.splice(to, 0, item);
    return array;
  };

  const handleOnClick = useCallback(
    (arrow) => {
      transitThumbnails(arrow);
      if (arrow === "prev") {
        setArrayOfImages(moveItem(arrayOfImages, arrayOfImages.length - 1, 0));
      } else {
        setArrayOfImages(moveItem(arrayOfImages, 0, arrayOfImages.length - 1));
      }
      transit();
    },
    [arrayOfImages]
  );

  return (
    <div className="home-container">
      <img
        src={arrayOfImages[0]}
        className={`${transition} carousel-backgroundImage`}
        alt="home background"
        // id="animate-image"
      />
      <TopBanner
        handleOnClick={handleOnClick}
        thumbnailAnimation={thumbnailAnimation}
        arrayOfImages={arrayOfImages}
      />
      <SlideShow />
    </div>
  );
}

export default HomeScreen;
